import repeatBlackIcon from "../../assets/img/modals/repeat-black.svg";
import repeatIcon from "../../assets/img/modals/repeat.svg";
import integration_google_analytics from "../../assets/img/modals/integration-google-analytics.svg";
import integration_facebook_analytics from "../../assets/img/modals/integration-facebook-pixel.svg";
import integration_mailchimp from "../../assets/img/modals/integration-mailchimp.svg";
import integration_convertkit from "../../assets/img/modals/integration-convertkit.svg";
import integration_sendinblue from "../../assets/img/modals/integration-sendinblue.svg";
import integration_zapier from "../../assets/img/modals/integration-zapier.svg";
import integration_custom_code from "../../assets/img/modals/integration-custom-code.svg";
import integration_meet from "../../assets/img/modals/integration-meet.svg";
import zoomIcon from "../../assets/img/modals/zoom-icon.svg";
import gearIcon from "../../assets/img/gear.svg";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../loader";
import returnIcon from "../../assets/img/modals/return.svg";
import { useDispatch, useSelector } from "react-redux";
import ModalErrors from "../errors/modal-errors";
import { toast } from "react-toastify"
import { apiUrl } from "../../utils/url";
import { FacebookPixelModal, GoogleAnalyticsModal, ConvertkitModal, MailchimpModal, SendinblueModal, ConnectZapierModal, CustomCodeModal } from "./integrations-modals";
import ConnectCalendarModal from "../connect-calendar-modal";
import getProducts from "../../services/getProducts";
import Teammoadal from "../teammember/team_modal";
import getProjects from "../../services/getProjects";
import { useAlert } from "../../contexts/alertContext";
import chevron_downIcon from "../../assets/img/modals/chevron-down.svg"
import NewConfirmationModal from "../common/new-confirmation-modal";

const IntegrationsPage = ({ setShowCustomizeParent }) => {
    const dispatch = useDispatch()
    const [show, setshow] = useState(false);
    const location = useLocation()
    const history = useHistory()
    const { subPath } = useParams();
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const isPro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const customizePageReducer = useSelector(state => state?.customizePageReducer);
    const integrationGoogleReducer = useSelector(state => state?.integrationGoogleReducer);
    const integrationFacebookReducer = useSelector(state => state?.integrationFacebookReducer); 
    const integrationMailChimpReducer = useSelector(state => state?.integrationMailChimpReducer);  
    const integrationConvertkitReducer = useSelector(state => state?.integrationConvertkitReducer);
    const integrationSendinblueReducer = useSelector(state => state?.integrationSendinblueReducer);
    const integrationEmailReducer = useSelector(state => state?.integrationEmailReducer); 
    const integrationContactReducer = useSelector(state => state?.integrationContactReducer); 
    const integrationProductReducer = useSelector(state => state?.integrationProductReducer); 
    const facebook_pixel = useSelector(state => state?.integrationFacebookReducer)
    const meeting_tokens = useSelector(state => state?.meetConnectedReducer);
    const [connectModal, setConnectModal] = useState(null);
    const [unlinkModal, setUnlinkModal] = useState({ show: false, type: null });
    const encryptedProjectSlug = slug ? window.btoa(slug) : null;
    const [error, setError] = useState([]);
    const [allProducts, setallProducts] = useState([]);
    const [showModalasd, setShowModalasd] = useState(false)
    const planType = useSelector(state => state?.plantypeReducer);
    const integrationzapierReducer = useSelector(state => state?.integrationZapierReducer);
    const integrationCustomCodeReducer = useSelector(state => state?.integrationCustomCodeReducer);
    const { setAlert } = useAlert();
    const [selectedTab, setSelectedTab] = useState('all_integrations');
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null, callback:null });
    const [lastSavedData, setLastSavedData] = useState({});
    useEffect(() => {
        if (!isPro) {
            setShowModalasd(true);
        } else {
            setShowModalasd(false);
            // callAllProducts();
            // getIntegrations()

        }
    }, [isPro, planType]);
    const getIntegrations = () => {
        getProjects(slug).then((response) => {
            dispatch({ type: "SET_MEET_CONNECTED", payload: { ...meeting_tokens, meet_token: response?.meet_token ? true : false } });
            window.opener?.postMessage({ type: 'meetToken', meetToken: response?.meet_token }, '*');
            let temp_obj = {};
            response?.settings?.map((val) => temp_obj = { ...temp_obj, [val?.key]: val?.value, project_id: val?.project_id });
            setIntegrationGoogle(temp_obj?.google_analytics?.length > 0 ? {
                analytics_id: temp_obj?.google_analytics,
                disconnect: 1
            } : { analytics_id: temp_obj?.google_analytics, })
            setIntegrationFacebook(temp_obj?.facebook_pixel?.length > 0 ? {
                pixel_id: temp_obj?.facebook_pixel,
                disconnect: 1
            } : { pixel_id: temp_obj?.facebook_pixel, })
            if (temp_obj?.mailchimp_settings)
                setIntegrationMailchimp({
                    ...JSON?.parse(temp_obj?.mailchimp_settings),
                    disconnect: 1
                })
            if (temp_obj?.convertkit_settings)
                setIntegrationConvertKit({
                    ...JSON?.parse(temp_obj?.convertkit_settings),
                    disconnect: 1
                })
            if (temp_obj?.sendinblue_settings)
                setIntegrationSendinBlue({
                    ...JSON?.parse(temp_obj?.sendinblue_settings),
                    disconnect: 1
                })
            if (temp_obj?.emailSignupUrl) {
                setIntegrationEmail({ emailSignupUrl: temp_obj?.emailSignupUrl });
            }
            if (temp_obj?.contactFormUrl) {
                setIntegrationContact({ contactFormUrl: temp_obj?.contactFormUrl });
            }
            if (temp_obj?.productSaleUrl) {
                setIntegrationProduct({ productSaleUrl: temp_obj?.productSaleUrl });
            }
        })
    }
    const callAllProducts = () => {
        getProducts(slug).then((response) => {
            setallProducts(response);
        })
    }
    // const meetConnected = allProducts?.data?.some(product => product?.calendar[0]?.meeting_location === 'google');
    const setParams = (tabName,reducer) => {
        showLoader()
        if (!isPro) {
            setShowModalasd(true);
            return;
        }else {
            setLastSavedData({ 
                modalType: tabName,
                data:reducer
            });
            setTimeout(() => {
                hideLoader()
                setConnectModal(tabName);
            }, 300);
        }
    }

    const diconnecting = (name, values, actionType) => {
        showLoader();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const errorList = []
        const formdata = new FormData();
        const formValues = { project: slug, tab: "integrations", integration: name, disconnect: 1 };

        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                formdata.append(key, formValues[key]);
            }
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + `/project/settings/update`, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {

                    let temp_obj = { ...values }
                    for (const key in temp_obj) {
                        if (Object.hasOwnProperty.call(temp_obj, key)) {
                            delete temp_obj["disconnect"];
                        }
                        dispatch({ type: actionType, payload: { ...temp_obj, [name]: null } });
                        setconfirmationModalShow({show:false});
                        // if (name == "mailchimp") {
                        //     setIntegrationMailchimp({ ...integrationMailChimpReducer, "disconnect": 0 })
                        // }
                        // else if (name == "convertkit") {
                        //     setIntegrationConvertKit({ ...integrationConvertkitReducer, "disconnect": 0 })
                        // }
                        // else if (name == "facebook") {
                        //     setIntegrationFacebook({ ...integrationFacebookReducer, "disconnect": 0 })
                        // }
                        // else if (name == "google") {
                        //     setIntegrationGoogle({ ...integrationGoogleReducer, "disconnect": 0 })
                        // }
                        // else if (name == "sendinblue") {
                        //     setIntegrationSendinBlue({ ...integrationSendinblueReducer, "disconnect": 0 })
                        // }
                    }
                    // if (type == "emailSignupUrl") {
                    //     setIntegrationEmail({ emailSignupUrl: null });
                    // }
                    // if (type == "contactFormUrl") {
                    //     setIntegrationContact({ contactFormUrl: null });
                    // }
                    // if (type == "productSaleUrl") {
                    //     setIntegrationProduct({ productSaleUrl: null });
                    // }
                    setAlert({ type: 'success', title: response?.status_message })

                    // toast.success(response?.status_message);
                    hideLoader();

                    setTimeout(() => {
                        handleClose(true)
                    }, 1000);
                }

                else {
                    let errorList = []
                    response?.errors?.link?.map((val) => errorList?.push(val));
                    response?.errors?.pixel_id?.map((val) => errorList?.push(val));
                    response?.errors?.analytics_id?.map((val) => errorList?.push(val));
                    // toast.error(response?.status_message)
                    setAlert({ type: 'error', title: response?.status_message })
                    hideLoader()
                }
            }).catch((err) => {
                hideLoader()
            })
    }
    const handleClose = (saved = false) => {
        if (typeof saved !== 'boolean' || !saved) {
            const actionTypes = {
                mailchimp: "SET_MAILCHIMP_INTEGRATION",
                convertkit: "SET_CONVERTKIT_INTEGRATION",
                facebookPixel: "SET_FACEBOOK_INTEGRATION",
                googleAnalytics: "SET_GOOGLE_INTEGRATION",
                sendinblue: "SET_SENDINBLUE_INTEGRATION",
                zapierEmailSignup: "SET_ZAPIER_INTEGRATION",
                zapierContactForm: "SET_ZAPIER_INTEGRATION",
                zapierProductSale: "SET_ZAPIER_INTEGRATION",
                custom_code: "SET_CUSTOM_CODE"
            };
    
            const actionType = actionTypes[connectModal];
    
            if (actionType) {
                dispatch({ type: actionType, payload: lastSavedData?.data });
            }
        }
        setConnectModal(null)
    }
    const handleSubmit = (e, values, integration) => {
        if (!showModalasd || (!integration.includes('emailSignupUrl') && !integration.includes('contactFormUrl') && !integration.includes('productSaleUrl'))) {
            e.preventDefault();
            showLoader();
            setError([]);
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const errorList = []
            const formdata = new FormData();
            let formValues = {...values, project: slug, integration: integration};
            // if (values?.disconnect == 1)
            //     formValues = { project: slug, tab: "integrations", integration: integration, disconnect: 1 };
            // else
            //     formValues = { ...values, project: slug, integration: integration };
            for (const key in formValues) {
                if (key != 'disconnect') {                    
                    if (Object.hasOwnProperty.call(formValues, key)) {
                        formdata.append(key, formValues[key]);
                    }
                }
            }

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(apiUrl + `/project/settings/update`, requestOptions)
                .then(async (res) => {
                    const response = await res?.json();
                    if (response?.status_code == 200) {

                        // toast.success(response?.status_message);
                        if (integration == "mailchimp") {
                            dispatch({ type: "SET_MAILCHIMP_INTEGRATION", payload: { ...integrationMailChimpReducer, "disconnect": 1 } })
                            // setIntegrationMailchimp({ ...integrationMailChimpReducer, "disconnect": 1 })
                        }
                        else if (integration == "convertkit") {
                            dispatch({ type: "SET_CONVERTKIT_INTEGRATION", payload: { ...integrationConvertkitReducer, "disconnect": 1 } })
                            // setIntegrationConvertKit({ ...integrationConvertkitReducer, "disconnect": 1 })
                        }
                        else if (integration == "facebook") {
                            dispatch({ type: "SET_FACEBOOK_INTEGRATION", payload: { ...integrationFacebookReducer, "disconnect": 1 } })
                            // setIntegrationFacebook({ ...integrationFacebookReducer, "disconnect": 1 })
                        }
                        else if (integration == "google") {
                            dispatch({ type: "SET_GOOGLE_INTEGRATION", payload: { ...integrationGoogleReducer, "disconnect": 1 } })
                            // setIntegrationGoogle({ ...integrationGoogleReducer, "disconnect": 1 })
                        }
                        else if (integration == "sendinblue") {
                            dispatch({ type: "SET_SENDINBLUE_INTEGRATION", payload: { ...integrationSendinblueReducer, "disconnect": 1 } })
                            // setIntegrationSendinBlue({ ...integrationSendinblueReducer, "disconnect": 1 })
                        }
                        else if (integration == "custom_code") {
                            dispatch({ type: "SET_CUSTOM_CODE", payload: { ...integrationCustomCodeReducer, "disconnect": 1 } })
                        }
                        // else if (integration == 'emailSignupUrl') {
                        //     dispatch({ type: "SET_ZAPIER_INTEGRATION", payload: { ...integrationEmailReducer, emailSignupUrl: true } })
                        //     // setIntegrationEmail({ emailSignupUrl: true });
                        // }
                        // else if (integration == 'contactFormUrl') {
                        //     dispatch({ type: "SET_ZAPIER_INTEGRATION", payload: { ...integrationContactReducer, contactFormUrl: true } })
                        //     // setIntegrationContact({ contactFormUrl: true });
                        // }
                        // else if (integration == 'productSaleUrl') {
                        //     dispatch({ type: "SET_ZAPIER_INTEGRATION", payload: { ...integrationProductReducer, productSaleUrl: true } })
                        //     // setIntegrationProduct({ productSaleUrl: true });
                        // }
                        hideLoader();
                        setAlert({ type: 'success', title: response?.status_message })
                        setTimeout(() => {

                            // console.log("handleSubmit")
                            handleClose(true)

                        }, 1000);
                    }

                    else {
                        let errorList = []
                        response?.errors?.link?.map((val) => errorList?.push(val));
                        response?.errors?.pixel_id?.map((val) => errorList?.push(val));
                        response?.errors?.analytics_id?.map((val) => errorList?.push(val));
                        response?.errors?.api_key?.map((val) => errorList?.push(val));
                        // setError(errorList)

                        // console.log(errorList)

                        if (errorList.length !== 0) {

                            // console.log("Error List", errorList.length)
                            errorList?.map((err) => {
                                return (
                                    setAlert({ type: 'error', title: err })
                                    // toast.error(err)
                                );
                            });
                        }
                        else {
                            setAlert({ type: 'error', title: response?.status_message })
                            // toast.error(response?.status_message)
                        }

                        // toast.error(response?.status_message)
                        hideLoader()
                    }
                }).catch((err) => {
                    hideLoader()
                })
        }
    }
    const handleMeetButtonClick = async () => {
        try {
            localStorage.setItem("last_activity", location.pathname)
            showLoader();
            const response = await fetch(apiUrl + "/google-meet?redirectUrl=" + localStorage.getItem("last_activity") + '&project_slug=' + encryptedProjectSlug, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            if (response.status === 200) {
                const responseData = await response.json();
                // Redirect to the OAuth URL
                localStorage.setItem("openIntegrationPage", true);

                window.location.href = responseData.data.oauth_url;
            } else {
                // Handle errors here
                console.error("API Error:", response.statusText);
                hideLoader();
            }

        } catch (error) {
            // Handle fetch or other errors
            console.error("Error:", error);
            hideLoader();
        }
    };
    const handleDisconnectMeetButtonClick = async () => {
        // if (meetConnected) {
        //     setUnlinkModal(true);
        // } else {
        try {
            showLoader();
            const response = await fetch(apiUrl + "/disconnect-google-account?&project_slug=" + encryptedProjectSlug, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            const responseData = await response.json();
            if (responseData.status_code === 200) {
                dispatch({ type: "SET_MEET_CONNECTED", payload: { ...meeting_tokens, meet_token: false } });
                // toast.success(responseData?.status_message);
                setAlert({ type: 'success', title: responseData?.status_message })
                hideLoader();
            } else {
                // Handle errors here
                hideLoader();
                if (responseData.status_code === 422 && responseData.errors && responseData.errors.google) {
                    // Handle Zoom account error
                    setUnlinkModal({ show: true, type: 'Google Meet' }); // Set unlinkModal to true
                } else {
                    // Handle other errors
                    console.error("API Error:", responseData.status_message);
                }
            }

        } catch (error) {
            // Handle fetch or other errors
            console.error("Error:", error);
            hideLoader();
        }
        // }
    };
    const handleZoom = async () => {
        showLoader();
        const formdata = new FormData();
        formdata.append("project", slug);
        const response = await fetch(apiUrl + "/zoom-connect/request", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: formdata,
        });

        const responseData = await response.json();
        if (responseData?.status_code === 200) {
            hideLoader();
            if (responseData?.data?.url) {
                window.location.href = responseData?.data?.url
            } else {
                toast?.error('Something went wrong')
            }
        } else {
            setAlert({ type: 'error', title: responseData?.status_message })
            // toast?.error(response?.status_message)
            hideLoader();
        }
    }
    const disconnectZoom = async () => {
        try {
            showLoader();
            const formdata = new FormData();
            formdata.append("project", slug);
            const response = await fetch(apiUrl + "/zoom-connect/disconnect", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formdata
            });

            const responseData = await response.json();
            if (responseData.status_code === 200) {
                dispatch({ type: "SET_MEET_CONNECTED", payload: { ...meeting_tokens, zoom_token: false } });
                setAlert({ type: 'success', title: responseData?.status_message })
                // toast.success(responseData?.status_message);
                hideLoader();
            } else {
                if (responseData.status_code === 422 && responseData.errors && responseData.errors.zoom) {
                    setUnlinkModal({ show: true, type: 'Zoom' }); // Set unlinkModal to true
                } else {
                    console.error("API Error:", responseData.status_message);
                }
                hideLoader();
            }

        } catch (error) {
            // Handle fetch or other errors
            console.error("Error:", error);
            hideLoader();
        }
    }
    const integrations = [
        {
            id: 'google_analytics',
            category: 'analytics',
            name: 'Google Analytics',
            description: 'Add your Google Analytics tracking ID to your Zaap page and get detailed insights on your traffic directly in Google Analytics.',
            image: integration_google_analytics,
            reducer: integrationGoogleReducer,
            connect_fun: () => setParams("googleAnalytics", integrationGoogleReducer),
            disconnect_fun: () => diconnecting("google", integrationGoogleReducer, "SET_GOOGLE_INTEGRATION"),
        },
        {
            id: 'facebook_pixel',
            category: 'marketing',
            name: 'Facebook Pixel',
            description: 'Add your Facebook Pixel ID to your Zaap page to track conversions, optimize ads, and retarget visitors directly in Facebook Ads Manager.',
            image: integration_facebook_analytics,
            reducer:facebook_pixel,
            connect_fun: () => setParams("facebookPixel", facebook_pixel),
            disconnect_fun: () => diconnecting("facebook", integrationFacebookReducer, "SET_FACEBOOK_INTEGRATION"),
        },
        {
            id: 'mailchimp',
            category: 'automations',
            name: 'Mailchimp',
            description: 'Sync new email subscribers from your Zaap page directly to your Mailchimp list.',
            image: integration_mailchimp,
            reducer:integrationMailChimpReducer,
            connect_fun: () => setParams("mailchimp", integrationMailChimpReducer),
            disconnect_fun: () => diconnecting("mailchimp", integrationMailChimpReducer, "SET_MAILCHIMP_INTEGRATION"),
        },
        {
            id: 'sendinblue',
            category: 'automations',
            name: 'SendinBlue',
            description: 'Sync new email subscribers from your Zaap page directly to your SendinBlue list.',
            image: integration_sendinblue,
            reducer:integrationSendinblueReducer,
            connect_fun: () => setParams("sendinblue", integrationSendinblueReducer),
            disconnect_fun: () => diconnecting("sendinblue", integrationSendinblueReducer, "SET_SENDINBLUE_INTEGRATION"),
        },
        {
            id: 'convertkit',
            category: 'automations',
            name: 'ConvertKit',
            description: 'Sync new email subscribers from your Zaap page directly to your ConvertKit list.',
            image: integration_convertkit,
            reducer:integrationConvertkitReducer,
            connect_fun: () => setParams("convertkit", integrationConvertkitReducer),
            disconnect_fun: () => diconnecting("convertkit", integrationConvertkitReducer, "SET_CONVERTKIT_INTEGRATION"),
        },
        {
            id: 'google_calendar',
            category: 'automations',
            name: 'Google Calendar',
            description: 'Automatically schedule meetings on your Google Calendar whenever someone books through your Zaap page.',
            image: integration_meet,
            reducer:meeting_tokens,
            connect_fun: () => handleMeetButtonClick(),
            disconnect_fun: () => handleDisconnectMeetButtonClick(),
        },
        {
            id: 'zoom',
            category: 'automations',
            name: 'Zoom',
            description: 'Automatically schedule meetings on Zoom whenever someone books through your Zaap page.',
            image: zoomIcon,
            reducer:meeting_tokens,
            connect_fun: () => handleZoom(),
            disconnect_fun: () => disconnectZoom(),
        },
        {
            id: 'zaiper_email_signup',
            category: 'automations',
            name: 'Zaiper (Email Signup)',
            description: 'Sync your Zaap email signups to any app with Zapier Webhooks.',
            image: integration_zapier,
            reducer:{...integrationzapierReducer,disconnect:integrationzapierReducer?.emailSignupUrl},
            connect_fun: () => setParams("zapierEmailSignup",integrationzapierReducer),
            disconnect_fun: () => diconnecting("emailSignupUrl", integrationzapierReducer, "SET_ZAPIER_INTEGRATION"),
        },
        {
            id: 'zaiper_product_sale',
            category: 'automations',
            name: 'Zaiper (Product Sale)',
            description: 'Sync your Zaap Product Sales to any app with Zapier Webhooks.',
            image: integration_zapier,
            reducer:{...integrationzapierReducer,disconnect:integrationzapierReducer?.productSaleUrl},
            connect_fun: () => setParams("zapierProductSale",integrationzapierReducer),
            disconnect_fun: () => diconnecting("productSaleUrl", integrationzapierReducer, "SET_ZAPIER_INTEGRATION"),
        },
        {
            id: 'zaiper_contact_form',
            category: 'automations',
            name: 'Zaiper (Contact Form)',
            description: 'Sync your Zaap Contact Form submissions to any app with Zapier Webhooks.',
            image: integration_zapier,
            reducer:{...integrationzapierReducer,disconnect:integrationzapierReducer?.contactFormUrl},
            connect_fun: () => setParams("zapierContactForm",integrationzapierReducer),
            disconnect_fun: () => diconnecting("contactFormUrl", integrationzapierReducer, "SET_ZAPIER_INTEGRATION"),
        },
        {
            id: 'custom_code',
            category: 'automations',
            name: 'Custom Code',
            description: 'Add custom code to your Zaap page to extend its functionality, integrate with third-party services, or run specific scripts tailored to your needs.',
            image: integration_custom_code,
            reducer:integrationCustomCodeReducer,
            connect_fun: () => setParams("custom_code",integrationCustomCodeReducer),
            disconnect_fun: () => diconnecting("custom_code", integrationCustomCodeReducer, "SET_CUSTOM_CODE"),
        }
    ];
    const tabs = [
        { id: 'all_integrations', label: 'All Integrations (' + integrations?.length +')'},
        { id: 'analytics', label: 'Analytics (' + integrations.filter(item => item?.category === 'analytics').length +')'},
        { id: 'marketing', label: 'Marketing (' + integrations.filter(item => item?.category === 'marketing').length +')'},
        { id: 'automations', label: 'Automations (' + integrations.filter(item => item?.category === 'automations').length +')'}
    ];

    const handleTabChange = (tabId) => {
        setSelectedTab(tabId);
    };
    
    const filteredIntegrations = selectedTab === 'all_integrations'
        ? integrations
        : integrations.filter(integration => integration?.category === selectedTab);
    const handleDisconnectClick = (integration) => {
        setconfirmationModalShow({
            show: true,
            icon: integration?.image,
            title: 'Disconnect ' + integration?.name,
            message: 'Are you sure that you want to disconnect '+integration?.name+'?',
            buttonText: 'Yes, Disconnect',
            callback: integration?.disconnect_fun
        });
    }
    return (
        <>
            <section class="customize-page-main int-3-b position-relative newpadding overflow-auto">
                <div class="cent-cont-u">
                    <span className="settings-section-heading font-weight-500">Integrations</span>
                    <div className="text-gray fs-14px font-weight-500 mt-1">Choose and connect the tools you use to streamline your workflow.</div>
                    <div className="toggle-input-checkbox integrations-toggle-tab">
                        {tabs.map((tab) => (
                            <React.Fragment key={tab?.id}>
                                <input type="radio" id={tab?.id} name="theme" style={{ display: 'none' }} value={tab?.id} checked={selectedTab === tab?.id} onChange={() => handleTabChange(tab?.id)}/>
                                <label htmlFor={tab?.id} className={selectedTab === tab?.id ? 'active' : ''}>
                                    {tab.label}
                                </label>
                            </React.Fragment>
                        ))}
                    </div>
                     <div className="integrations-tab-dropdown dropdown-toggle noselect" id="integrations-tab-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        <span>
                            {tabs?.find(tab => tab?.id === selectedTab)?.label}
                        </span>
                        <img src={chevron_downIcon} width="10px" alt="Dropdown Icon" />
                    </div>
        
                    <ul className="dropdown-menu integrations-tab-dropdown-menu" aria-labelledby="integrations-tab-dropdown">
                        {tabs.map((tab) => (
                            selectedTab !== tab?.id &&
                                <li key={tab?.id} onClick={() => handleTabChange(tab?.id)}>
                                    <a className={`dropdown-item`}>
                                        {tab?.label}
                                    </a>
                                </li>
                            
                        ))}
                    </ul>
                    <div class="int-u">
                        {filteredIntegrations.map((integration)  => (
                        <div class="integration-card">
                            <div className="d-flex align-items-center gap-2">
                                <img className="integration-card-img icon-shadow" src={integration?.image} alt="" />
                                <span class="integration-heading">{integration?.name}</span>
                            </div>
                            <label class="text-lightgray">{integration?.description}</label>
                            <div className="int-btn-cont">

                                {
                                    integration?.id == 'zoom'
                                     ?
                                     <>
                                        <button type="button" disabled className="blue-btn opacity-50" >
                                            <span>Coming Soon</span>
                                            <small className="blue-badge">
                                                <img src={returnIcon} alt="" />
                                            </small>
                                        </button>
                                        <a href="https://www.zaap.ai/support#tutorials" type="button" className="read-docs" target="_blank">
                                            Read Docs
                                        </a>
                                    </>
                                        :
                                    (!integration?.reducer?.disconnect) ?
                                        <>
                                            <button type="submit" onClick={() => integration?.connect_fun()} className="blue-btn" >
                                                <span>Connect</span>
                                                <small className="blue-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <a href="https://www.zaap.ai/support#tutorials" type="button" className="read-docs" target="_blank">
                                                Read Docs
                                            </a>
                                        </>
                                        :
                                        <>
                                            <button class="new-primary-btn customDomainModal"
                                                onClick={() => handleDisconnectClick(integration)}>
                                                Disconnect
                                                <small className="primary-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <button className="btn-gray" onClick={() => integration?.connect_fun()}>
                                                <img src={gearIcon} className="white-gear" alt="" srcset="" />
                                            </button>
                                        </>
                                }
                            </div>
                        </div>
                        ))}

                        {/* <div class="integration-card">
                            <div className="d-flex align-items-center gap-2">
                                <img className="integration-card-img icon-shadow" src={integration_facebook_analytics} alt="" />
                                <span class="integration-heading">Facebook Pixel</span>
                            </div>
                            <label class="text-lightgray">Facebook pixel is a free tool that tracks visitor activity on your website and aids in creating targeted ads.</label>
                            <div className="int-btn-cont">

                                {
                                    (!facebook_pixel?.disconnect) ?
                                        <>
                                            <button type="submit" onClick={() => setParams("facebookPixel")} className="blue-btn">
                                                <span>Connect</span>
                                                <small className="blue-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <a href="https://www.zaap.ai/support#tutorials" type="button" className="read-docs" target="_blank">
                                                Read Docs
                                            </a>
                                        </>
                                        :
                                        <>
                                            <button class="new-primary-btn customDomainModal"
                                                onClick={() => diconnecting("facebook", integrationFacebookReducer, "SET_FACEBOOK_INTEGRATION")}>
                                                Disconnect
                                                <small className="primary-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <button className="btn-gray">
                                                <img src={gearIcon} className="white-gear" alt="" srcset="" />
                                            </button>
                                        </>
                                }
                            </div>
                        </div>

                        <div class="integration-card">
                            <div className="d-flex align-items-center gap-2">
                                <img className="integration-card-img icon-shadow" src={integration_mailchimp} alt="" />
                                <span class="integration-heading">Mailchimp</span>
                            </div>
                            <label class="text-lightgray">Mailchimp is a email marketing automation platform that offers campaign builder, audience segmentation, landing pages and websites.</label>
                            <div className="int-btn-cont">

                                {
                                    (!integrationMailChimpReducer?.disconnect) ?
                                        <>
                                            <button type="submit" onClick={() => setParams("mailchimp")} className="blue-btn">
                                                <span>Connect</span>
                                                <small className="blue-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <a href="https://www.zaap.ai/support#tutorials" type="button" className="read-docs" target="_blank">
                                                Read Docs
                                            </a>
                                        </>
                                        :
                                        <>
                                            <button class="new-primary-btn customDomainModal"
                                                onClick={() => diconnecting("mailchimp", integrationMailChimpReducer, "SET_MAILCHIMP_INTEGRATION")}>
                                                Disconnect
                                                <small className="primary-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <button className="btn-gray">
                                                <img src={gearIcon} className="white-gear" alt="" srcset="" />
                                            </button>
                                        </>
                                }
                            </div>
                        </div>

                        <div class="integration-card">
                            <div className="d-flex align-items-center gap-2">
                                <img className="integration-card-img icon-shadow" src={integration_convertkit} alt="" />
                                <span class="integration-heading">Convertkit</span>
                            </div>
                            <label class="text-lightgray">ConvertKit is a email marketing solution that helps build and manage lists and run automated campaigns.</label>
                            <div className="int-btn-cont">

                                {
                                    (!integrationConvertkitReducer?.disconnect) ?
                                        <>
                                            <button type="submit" onClick={() => setParams("convertkit")} className="blue-btn">
                                                <span>Connect</span>
                                                <small className="blue-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <a href="https://www.zaap.ai/support#tutorials" type="button" className="read-docs" target="_blank">
                                                Read Docs
                                            </a>
                                        </>
                                        :
                                        <>
                                            <button class="new-primary-btn customDomainModal"
                                                onClick={() => diconnecting("convertkit", integrationConvertkitReducer, "SET_CONVERTKIT_INTEGRATION")}>
                                                Disconnect
                                                <small className="primary-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <button className="btn-gray">
                                                <img src={gearIcon} className="white-gear" alt="" srcset="" />
                                            </button>
                                        </>
                                }
                            </div>
                        </div>

                        <div class="integration-card">
                            <div className="d-flex align-items-center gap-2">
                                <img className="integration-card-img icon-shadow" src={integration_sendinblue} alt="" />
                                <span class="integration-heading">SendinBlue</span>
                            </div>
                            <label class="text-lightgray">Sendinblue is a email marketing and automation platform that helps businesses connect with customers via email, SMS, and chat.</label>
                            <div className="int-btn-cont">

                                {
                                    (!integrationSendinblueReducer?.disconnect) ?
                                        <>
                                            <button type="submit" onClick={() => setParams("sendinblue")} className="blue-btn">
                                                <span>Connect</span>
                                                <small className="blue-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <a href="https://www.zaap.ai/support#tutorials" type="button" className="read-docs" target="_blank">
                                                Read Docs
                                            </a>
                                        </>
                                        :
                                        <>
                                            <button class="new-primary-btn customDomainModal"
                                                onClick={() => diconnecting("sendinblue", integrationSendinblueReducer, "SET_SENDINBLUE_INTEGRATION")}
                                            >
                                                Disconnect
                                                <small className="primary-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <button className="btn-gray">
                                                <img src={gearIcon} className="white-gear" alt="" srcset="" />
                                            </button>
                                        </>
                                }
                            </div>
                        </div>
                        <div class="integration-card">
                            <div className="d-flex align-items-center gap-2">
                                <img className="integration-card-img icon-shadow" src={integration_meet} alt="" />
                                <span class="integration-heading">Google Calendar</span>
                            </div>
                            <label class="text-lightgray">Google Calendar is a web and mobile app that lets users create and edit events, set reminders, invite others, and access shared calendars.</label>
                            <div className="int-btn-cont">

                                {
                                    (!meeting_tokens?.meet_token) ?
                                        <>
                                            <button type="submit" onClick={handleMeetButtonClick} className="blue-btn">
                                                <span>Connect</span>
                                                <small className="blue-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <a href="https://www.zaap.ai/support#tutorials" type="button" className="read-docs" target="_blank">
                                                Read Docs
                                            </a>
                                        </>
                                        :
                                        <>
                                            <button onClick={handleDisconnectMeetButtonClick}
                                                class="new-primary-btn customDomainModal">
                                                Disconnect
                                                <small className="primary-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <button className="btn-gray">
                                                <img src={gearIcon} className="white-gear" alt="" srcset="" />
                                            </button>
                                        </>
                                }
                            </div>
                        </div>
                        <div class="integration-card">
                            <div className="d-flex align-items-center gap-2">
                                <img className="integration-card-img icon-shadow" src={zoomIcon} alt="" />
                                <span class="integration-heading">Zoom</span>
                            </div>
                            <label class="text-lightgray">Automatically schedule meetings on Zoom whenever someone books through your Zaap page.</label>
                            <div className="int-btn-cont">
                                {
                                    !meeting_tokens?.zoom_token ?
                                        <>
                                            <button type="submit" onClick={handleZoom} className="blue-btn">
                                                <span>Connect</span>
                                                <small className="blue-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <a href="https://www.zaap.ai/support#tutorials" type="button" className="read-docs" target="_blank">
                                                Read Docs
                                            </a>
                                        </>
                                        :
                                        <>
                                            <button onClick={disconnectZoom} class="new-primary-btn customDomainModal">
                                                Disconnect
                                                <small className="primary-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <button className="btn-gray">
                                                <img src={gearIcon} className="white-gear" alt="" srcset="" />
                                            </button>
                                        </>
                                }
                            </div>
                        </div>
                        <div class="integration-card">
                            <div className="d-flex align-items-center gap-2">
                                <img src={integration_zapier} className="icon-shadow" alt="" />
                                <span class="integration-heading">Zaiper (Email Signup)</span>
                            </div>
                            <label class="text-lightgray">Sync your Zaap email signups to any app with Zapier Webhooks.</label>
                            <div className="int-btn-cont">

                                {
                                    (!integrationEmailReducer?.emailSignupUrl) ?
                                        <>
                                            <button type="submit" onClick={() => setParams("zapierEmailSignup")} className="blue-btn">
                                                <span>Connect</span>
                                                <small className="blue-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <a href="https://www.zaap.ai/support#tutorials" type="button" className="read-docs" target="_blank">
                                                Read Docs
                                            </a>
                                        </>
                                        :
                                        <>
                                            <button onClick={() => diconnecting("emailSignupUrl", integrationzapierReducer, "SET_ZAPIER_INTEGRATION")}
                                                class="new-primary-btn mt-27">
                                                Disconnect
                                                <small className="primary-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <button className="btn-gray">
                                                <img src={gearIcon} className="white-gear" alt="" srcset="" />
                                            </button>
                                        </>
                                }
                            </div>
                        </div>
                        <div class="integration-card">
                            <div className="d-flex align-items-center gap-2">
                                <img src={integration_zapier} className="icon-shadow" alt="" />
                                <span class="integration-heading">Zaiper (Product Sale)</span>
                            </div>
                            <label class="text-lightgray">Sync your Zaap Product Sales to any app with Zapier Webhooks. &nbsp;</label>
                            <div className="int-btn-cont">

                                {
                                    (!integrationProductReducer?.productSaleUrl) ?
                                        <>
                                            <button type="submit" onClick={() => setParams("zapierProductSale")} className="blue-btn">
                                                <span>Connect</span>
                                                <small className="blue-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <a href="https://www.zaap.ai/support#tutorials" type="button" className="read-docs" target="_blank">
                                                Read Docs
                                            </a>
                                        </>
                                        :
                                        <>
                                            <button onClick={() => diconnecting("productSaleUrl", integrationzapierReducer, "SET_ZAPIER_INTEGRATION")}
                                                class="new-primary-btn mt-27">
                                                Disconnect
                                                <small className="primary-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <button className="btn-gray">
                                                <img src={gearIcon} className="white-gear" alt="" srcset="" />
                                            </button>
                                        </>
                                }
                            </div>
                        </div>
                        <div class="integration-card">
                            <div className="d-flex align-items-center gap-2">
                                <img src={integration_zapier} className="icon-shadow" alt="" />
                                <span class="integration-heading">Zaiper (Contact Form)</span>
                            </div>
                            <label class="text-lightgray">Sync your Zaap Contact Form submissions to any app with Zapier Webhooks.</label>
                            <div className="int-btn-cont">

                                {
                                    (!integrationContactReducer?.contactFormUrl) ?
                                        <>
                                            <button type="submit" onClick={() => setParams("zapierContactForm")} className="blue-btn">
                                                <span>Connect</span>
                                                <small className="blue-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <a href="https://www.zaap.ai/support#tutorials" type="button" className="read-docs" target="_blank">
                                                Read Docs
                                            </a>
                                        </>
                                        :
                                        <>
                                            <button onClick={() => diconnecting("contactFormUrl", integrationzapierReducer, "SET_ZAPIER_INTEGRATION")}
                                                class="new-primary-btn mt-27">
                                                Disconnect
                                                <small className="primary-badge">
                                                    <img src={returnIcon} alt="" />
                                                </small>
                                            </button>
                                            <button className="btn-gray">
                                                <img src={gearIcon} className="white-gear" alt="" srcset="" />
                                            </button>
                                        </>
                                }
                            </div>
                        </div> */}
                    </div>
                </div>
                {showModalasd && <Teammoadal showModal={showModalasd} text={'Integrations is a Pro feature. <br /> Upgrade to Zaap Pro to unlock this feature.'} isUltra={false} />}
            </section>
            {/* <section className="container settings-section"style={{padding:"10px"}}>
            
            <div class="container">
                <h1 class="acount-header mb-0" >Integrations</h1>
            </div>
            <div class="container mb-5 ">
                <div className='d-flex justify-content-between align-items-center mb-3' style={{background:"#EEEDEE",borderRadius:"12px",padding:'12px'}}>
                    <div class="form-group d-flex justify-content-start align-items-center w-100">
                        <img src={payout_settings_stripe} width="40px" alt="" />
                        <p className="text-gray font-weight-500 mb-0 ms-2">Stripe</p>    
                    </div>
                    {stripeConnectReducer?.stripe == 0 ? 
                        <button type="button" className="btn btn-semi-rounded" style={{background: '#FAFAFA'}} onClick={connectStripe}><b>Connect</b></button> : 
                        <button type="button" className="btn btn-semi-rounded" style={{background: '#FAFAFA'}} onClick={disconnectSripe}><b>Disconnect</b></button>
                    }
                    </div>
                <div className='d-flex justify-content-between align-items-center mb-3' style={{background:"#EEEDEE",borderRadius:"12px",padding:'12px'}}>
                    <div class="form-group d-flex justify-content-start align-items-center w-100">
                        <img src={payout_settings_paypal} width="40px" alt="" />
                        <p className="text-gray font-weight-500 mb-0 ms-2">Paypal</p>    
                    </div>
                    {stripeConnectReducer?.paypal == 0 ? 
                        <button type="button" className="btn btn-semi-rounded" style={{background: '#FAFAFA'}} onClick={connectPayPal}><b>Connect</b></button>:
                        <button type="button" className="btn btn-semi-rounded" style={{background: '#FAFAFA'}} onClick={disconnectModal}><b>Disconnect</b></button>
                    }
                    </div>
            </div>
            {payoutDisconnectModal && <DisonnectModal show={payoutDisconnectModal} setShow={setpayoutDisconnectModal} disconnect={onDisconnect} />}

            </section> */}
            <NewConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow}  callback={confirmationModalShow?.callback}/>
            {connectModal == "zapierEmailSignup" && <ConnectZapierModal type={"emailSignupUrl"} text="Email Signup" show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />}
            {connectModal == "zapierProductSale" && <ConnectZapierModal type={"productSaleUrl"} text="Product Sale" show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />}
            {connectModal == "zapierContactForm" && <ConnectZapierModal type={"contactFormUrl"} text="Contact Form" show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />}
            {connectModal == "googleAnalytics" && <GoogleAnalyticsModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />}
            {connectModal == "facebookPixel" && <FacebookPixelModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />}
            {connectModal == "mailchimp" && <MailchimpModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />}
            {connectModal == "convertkit" && <ConvertkitModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />}
            {connectModal == "sendinblue" && <SendinblueModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />}
            {connectModal == "custom_code" && <CustomCodeModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose} />}
            <ConnectCalendarModal show={unlinkModal?.show} setShow={setUnlinkModal} type={unlinkModal?.type} TwoButtons={false} text={`This ${unlinkModal?.type} Account is being used for existing products. Please unlink ${unlinkModal?.type} from your products before continuing`} />
        </>
    )
}

export default IntegrationsPage;
